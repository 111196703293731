


















import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ToolboxMessageContainer from '@/components/toolbox/message/ToolboxMessageContainer.vue';
import GroupType from '@/utils/enums/chat/GroupType';
import MessageGroup from '@/models/graphql/MessageGroup';
import Exhibitor from '@/models/graphql/Exhibitor';
import { namespace } from 'vuex-class';

const exhibitorStore = namespace('ExhibitorStore');

/* eslint-disable @typescript-eslint/camelcase */

@Component({
  components: { ToolboxMessageContainer },
})
export default class ExhibitorPortalMessageWidget extends mixins(
  VueRegisterStoreWidget,
  VueBaseWidget,
) {
  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private fetchExhibitor!: Exhibitor;

  private height = '86vh';

  private basicFilter: object | null = null;

  private get chatReps(): string[] {
    // eslint-disable-next-line no-underscore-dangle
    return (this.fetchExhibitor._chatReps || []).map((rep) => rep.user?.uid || '');
  }

  created(): void {
    this.setDataConfig();
    this.initComponentConfig();
    this.$root.$on('first-groups-page-loaded', () => {
      const { group } = this.$route.query;
      if (group) {
        this.$store.dispatch(
          'ChatDispatcherStore/ExhibitorPortalMessageWidgetStore/openSelectedConversation',
          {
            group,
            myUid: this.authUser.uid,
          },
        );
      }
    });
    window.addEventListener('resize', this.setComponentHeight);
  }

  beforeDestroy(): void {
    window.removeEventListener('resize', this.setComponentHeight);
  }

  updated(): void {
    this.setComponentHeight();
  }

  @Watch('isReadyToDisplay', { immediate: true })
  private initComponentConfig(): void {
    const { companyId } = this.$route.params;
    if (companyId && this.isReadyToDisplay) {
      this.basicFilter = {
        type_not: GroupType.QNA,
        users: { uid: this.authUser.uid },
        target: {
          uid: companyId,
        },
      };
      const groupValidationCallback = (messageGroup: MessageGroup): boolean => messageGroup.groupType !== GroupType.QNA
        && (messageGroup.users || []).findIndex((user) => user.uid === this.authUser.uid) > -1
        && (messageGroup.target === null || messageGroup.target?.uid === companyId);

      this.$store.commit(
        'ChatDispatcherStore/ExhibitorPortalMessageWidgetStore/setValidateGroupFn',
        groupValidationCallback,
      );
    }
  }

  private setComponentHeight(): void {
    if (this.$el && this.$el.getBoundingClientRect().top) {
      this.height = `calc(100vh - ${this.$el && this.$el.getBoundingClientRect().top}px)`;
    }
  }
}
